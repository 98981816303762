<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">综合信息查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="班级搜索" style="display: flex; align-items: center"
              class="searchboxIclearProjectSearchModeltem ci-full">
              <span class="itemLabel">班级搜索:</span>
              <el-select v-model="projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superPorjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span class="select-header-1">班级编号</span>
                    <span class="select-header-2">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="projectCode" v-on:input="superPorjectSelect" type="text" size="small"
                      placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="projectName" v-on:input="superPorjectSelect" type="text" size="small"
                      placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span class="select-header-3" style="width: 150px; font-size: 13px">{{ item.projectCode }}</span>
                  <span class="select-header-4" style="width: 250px; margin-left: 50px; font-size: 13px">{{
                    item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="学员搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel">学员搜索:</span>
              <el-select v-model="userId" placeholder="请选择" no-data-text="没有数据" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style="color: #8492a6;float: left;font-size: 13px;width: 100px;">学员姓名</span>
                    <span style="color: #8492a6;float: left;font-size: 13px;margin-left: 50px;width: 140px;">身份证号码</span>
                    <span style=" color: #8492a6; float: left;font-size: 13px;margin-left: 50px;width: 100px;">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="userName" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="idcard" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="mobile" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="queryData">查询</el-button>
          </div>
          <div class="searchbox" style="font-size: 14px;color: red;">
            下载文件为班级所有学员信息，其中学习记录汇总（按登录）、学习记录汇总（按课节）、考试试卷仅下载已结业学员信息；PDF格式档案请在班级结束后下载。
          </div>
        </div>
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="学员注册信息" name="type_1">
            <type_1 ref="type_1" :projectId="projectId" :userId="userId" :tabs="activeName" />
          </el-tab-pane>
          <el-tab-pane label="签到信息" name="type_2">
            <type_2 ref="type_2" :projectId="projectId" :userId="userId" :tabs="activeName" />
          </el-tab-pane>
          <el-tab-pane label="学习记录" name="type_3">
            <type_3 ref="type_3" :projectId="projectId" :userId="userId" :tabs="activeName" />
          </el-tab-pane>
          <el-tab-pane label="答疑信息" name="type_4">
            <type_4 ref="type_4" :projectId="projectId" :userId="userId" :tabs="activeName" />
          </el-tab-pane>
          <el-tab-pane label="考试信息" name="type_5">
            <type_5 ref="type_5" :projectId="projectId" :userId="userId" :tabs="activeName" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import type_1 from "@/views/set/ciqSHqingpu/type_1.vue"; //学员注册信息
import type_2 from "@/views/set/ciqSHqingpu/type_2.vue"; //签到信息
import type_3 from "@/views/set/ciqSHqingpu_new/type_3.vue"; //学习记录
import type_4 from "@/views/set/ciqSHqingpu/type_4.vue"; //答疑信息
import type_5 from "@/views/set/ciqSHqingpu/type_5.vue"; //考试信息
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "set_ciqSHqingpu_new_ComprehensiveInformationQuery",
  components: {
    type_1,
    type_2,
    type_3,
    type_4,
    type_5
  },
  mixins: [List],
  data() {
    return {
      // 班级id
      projectId: "",
      // 班级编码
      projectCode: "",
      // 班级名称
      projectName: "",
      // 班级下拉数据
      projectList: [{}],
      // 学员id 
      userId: "",
      // 学员名称
      userName: "",
      // 身份证
      idcard: "",
      // 手机号
      mobile: "",
      // 学员下拉数据
      userList: [{}],
      // tap选项卡的选中name
      activeName: "type_1",
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    })
  },
  mounted() {
  },
  created() {
    this.superPorjectSelect();
  },
  methods: {
    // 点击 - 查询
    queryData() {
      if (this.projectId) {
        if (this.activeName == 'type_4') {
          this.$refs[this.activeName].getData(1, 10);
        } else {
          this.$refs[this.activeName].getData();
        }
      } else {
        this.$message({
          message: '请先选择班级',
          type: 'warning'
        });
      }
    },
    // 点击tabs选项卡
    tabChange(tab, event) {
      this.activeName = tab.name;
    },
    // 查询 - 班级列表
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.projectName,
          projectCode: this.projectCode,
        },
      ).then((res) => {
        if (res.data.list.length < 1) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    // 查询 - 班级列表 - 下拉框出现/隐藏时触发
    clearProjectSearchModel(e) {
      if (e) {
        this.projectName = "";
        this.projectCode = "";
        this.superPorjectSelect();
      }
    },
    // 查询 - 学员列表
    superUserSelect(e) {
      this.$post(
        "/biz/user/companyUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.userName,
          idcard: this.idcard,
          mobile: this.mobile,
          projectId: this.projectId
        }
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    // 查询 - 学员列表 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.userName = "";
        this.idcard = "";
        this.mobile = "";
        this.superUserSelect();
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;

  .select-header-1 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    width: 150px;
  }

  .select-header-2 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    margin-left: 50px;
    width: 250px;
  }
}
</style>
